import React, { useState, useEffect, useRef } from "react";
import { Wheel } from "spin-wheel";
import Notification from "./popup";
import PopupModal from "./popup-thongbao";
import { useRecoilState } from "recoil";
import { IdKhachhangState, NhanvienState, userState } from "../state";
import UserCard from "./user-card";
import NamImage from "./images/png-clipart.png";
import NuImage from "./images/Female-icon-2.png";
import titleImg from "./images/background/vongquay.png";
const vien =
  "https://salereport.superweb.xyz/storage/images/phanthuong/vien.svg";
const mayman =
  "https://salereport.superweb.xyz/storage/images/phanthuong/g6.png";
import hopqua from "./images/hopqua.jpg";
import cuonsach from "./images/cuonsach.jpg";
const SpinWheelComponent = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [startPressTime, setStartPressTime] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [notification, setNotification] = useState(false);
  const [vienload, setvienload] = useState("");

  const [isPopupCheck, setIsPopupCheck] = useState(false);
  const [customer, setCustomer] = useState();
  const [sanpham, setsanpham] = useState(false);
  const [solanquay, setsolanquay] = useState(0);
  const [itemtrungthuong, setitemtrungthuong] = useState();
  const [segments, setSegments] = useState([]);
  const [isCamOn, setIsCamOn] = useState(false);
  const wheelContainer = useRef(null);
  const wheelRef = useRef(null);
  const [Nhanvien, setNhanvien] = useRecoilState(NhanvienState);
  const [idNguoiDung, setIdNguoiDung] = useRecoilState(IdKhachhangState);
  const [khachHang, setKhachHang] = useRecoilState(userState);
  const [ketquavalue, setketquavalue] = useState([]);
  const [showGender, setShowGender] = useState(false);
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [showAge, setShowAge] = useState(false);
  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://luckywheel.superweb.xyz/api/sanpham?gender=${gender}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      const totalWinRate = data.data.reduce(
        (sum, product) => sum + product.win_rate,
        0
      );
      let alternatingProducts = [];
      if (1000 - totalWinRate === 0) {
        alternatingProducts = [...data.data];
      } else {
        const luckyProduct = {
          name: "Chúc bạn may mắn lần sau",
          image: mayman,
          id: 0,
          description: "Chúc bạn may mắn lần sau",
          win_rate: 1000 - totalWinRate,
          conlai: 1000,
        };
        alternatingProducts = [...data.data, luckyProduct];
      }
      setSegments(alternatingProducts);
      setsanpham(true);
    } catch (err) {
      console.error("Fetch error:", err);
    }
  };

  useEffect(() => {
    const loadAllImages = async () => {
      if (sanpham) {
        try {
          const loadedSegments = await Promise.all(
            segments.map(async (segment) => {
              const img = new Image();
              img.src = segment.image;
              await img.decode();
              img.height = 130;
              img.width = 130;
              return { ...segment, img };
            })
          );
          const imgVien = new Image();
          imgVien.src = vien;
          await imgVien.decode();
          setvienload(imgVien);
          setSegments(loadedSegments);
          setImagesLoaded(true);
        } catch (error) {
          console.error("An image failed to load:", error);
        }
      }
    };
    loadAllImages();
  }, [sanpham]);

  useEffect(() => {
    if (imagesLoaded && wheelContainer.current) {
      const wheelProps = {
        name: "Rock Paper Scissors",
        itemBackgroundColors: ["hsl(310, 100%, 95%)"],
        lineColor: "hsl(350, 20%, 40%)",
        lineWidth: 2,
        borderColor: "hsl(350, 20%, 40%)",
        borderWidth: 4,
        itemLabelFontSizeMax: 20,

        overlayImage: vienload,
        items: segments.map((segment) => ({
          image: segment.img,
          imageRadius: 0.6,
          imageScale: 1.2,
          weight: 2,
        })),
      };
      wheelRef.current = new Wheel(wheelContainer.current, wheelProps);
    }
  }, [imagesLoaded, segments]);

  const handleSpin = () => {
    if (solanquay === 1) {
      setIsPopupCheck(true);
      return;
    }
    if (isSpinning || !wheelRef.current) return;

    setIsSpinning(true);
    setStartPressTime(Date.now());
    const a = [];
    let j = 0;

    segments.forEach((product) => {
      if (product.conlai != 0) {
        for (let i = 0; i < product.win_rate; i++) {
          a.push(j);
        }
      }
      j++;
    });

    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }

    const randomIndex2 = Math.floor(Math.random() * a.length);
    const selectedProductId = a[randomIndex2];

    let randomIndex = selectedProductId;
    setSelectedIndex(randomIndex);

    const pressDuration = Date.now() - startPressTime;
    const spinDuration = Math.max(5000, pressDuration);

    wheelRef.current.spinToItem(randomIndex, spinDuration, true, 3, 1);

    guiketqua(segments[randomIndex]);
    setTimeout(() => {
      setIsSpinning(false);
      showNotification(
        `${segments[randomIndex].name}`,
        "success",
        segments[randomIndex].img.src
      );
      setSelectedItem(`You selected: ${segments[randomIndex].name}`);
    }, spinDuration + 500);
  };

  useEffect(() => {
    login;
  }, [idNguoiDung, Nhanvien, gender, dateOfBirth]);

  const login = async () => {
    const idnguoidung = idNguoiDung;
    const idnhanvien = Nhanvien;

    if (
      idnhanvien == null ||
      idnguoidung == null ||
      idnguoidung == "" ||
      idnhanvien == ""
    ) {
      return;
    }
    try {
      const response = await fetch(
        "https://luckywheel.superweb.xyz/api/login",
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id_zalo: idnguoidung,
            referral_code: idnhanvien,
            gioitinh: gender,
            ngaysinh: dateOfBirth,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setKhachHang(errorData.data.name);
        setketquavalue(errorData.data.ketqua);
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setCustomer(data.data.id);
      setKhachHang(data.data.name);
      setketquavalue(data.data.ketqua);
      setsolanquay(0);
      setIsPopupCheck(false);
    } catch (err) {
      setIsPopupCheck(true);
      setsolanquay(1);
      console.error("Fetch error: 2", err.message);
    }
  };

  const guiketqua = (item) => {
    const idnhanvien = Nhanvien;
    fetch("https://luckywheel.superweb.xyz/api/ketqua", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        item: item.id,
        customer: customer,
        nhanvien: Nhanvien,
        description: item.description,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setitemtrungthuong(data.ketqua);
        setsolanquay(1);
        setketquavalue(data.ketqua);
      })
      .catch((error) => {
        console.error("Error sending referral code:", error);
      });
  };
  const showNotification = (message, type, image) => {
    setNotification({ message, type, image });
  };

  const closeNotification = () => {
    setNotification(null);
    if (segments[selectedIndex].name === "Chúc bạn may mắn lần sau") {
      return;
    }
  };

  const togglePopupCheck = () => {
    setIsPopupCheck(!isPopupCheck);
  };

  const toggleCamOn = () => {
    setIsCamOn(false);
  };
  const [theledialog, settheledialog] = useState(false);
  const thele = () => {
    settheledialog(true);
  };
  const [ketquadialog, setketquadialog] = useState(false);
  const ketqua = () => {
    setketquadialog(true);
  };
  function formatDate(product) {
    const date = new Date(product);
    const formattedDate = date.toLocaleDateString("en-CA");
    return formattedDate;
  }

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };
  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  const handleSubmit = () => {
    fetchData();
    login();
    if (dateOfBirth) {
      const age = calculateAge(dateOfBirth);
      console.log(`Tuổi: ${age}`);
      if (age < 18 || age > 60) {
        setShowAge(true);
      }
    }
    setShowGender(false);
  };
  const handleInputChange = (event) => {
    setDateOfBirth(event.target.value);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id_zalo = queryParams.get("id");

    if (id_zalo) {
      setShowGender(true);
    }
  }, []);
  return (
    <div className="vongquay container mx-auto px-4">
      <UserCard />
      <img
        src={titleImg}
        alt="Vòng quay may mắn"
        style={{
          width: "260px",
          height: "250px",
          position: "absolute",
          zIndex: "999",
          marginTop: "60px",
        }}
      />
      <div
        ref={wheelContainer}
        className="wheelcontainer"
        style={{
          marginTop: "192px",
          width: "390px",
          height: "300px",
          position: "relative",
          pointerEvents: isSpinning ? "none" : "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-20px",
            left: "44%",
            width: "50px",
            height: "50px",
            clipPath:
              "polygon(31% 0, 50% 20%, 67% 0, 93% 4%, 100% 35%, 50% 100%, 0 33%, 6% 4%)",
            backgroundColor: "red",
          }}
        />
      </div>
      <div>
        {/* <button
          className={} // Add shake effect only when not disabled
          style={{
            width: "200px",
            height: "40px",
            borderRadius: "5%",
            backgroundColor: "#e3e317",
            color: "#fff",
            fontSize: "20px",
            border: "2px solid yellow",
            cursor: isSpinning || !imagesLoaded ? "not-allowed" : "pointer",
            divShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
          }}
        >
          {isSpinning ? "Đang quay" : "Quay ngay"}
        </button> */}
      </div>
      <div>
        <div>
          <button
            className="pushable"
            onMouseDown={() => setStartPressTime(Date.now())}
            onMouseUp={handleSpin}
            disabled={isSpinning || !imagesLoaded}
          >
            <span className="front">
              {" "}
              {isSpinning ? "Đang quay" : "Quay ngay"}
            </span>
          </button>
          <div className="footer">
            <div onClick={() => thele()}>
              <img src={cuonsach} alt="Thể lệ" className="image-footer" />
              <p className="p-footer"> Thể lệ</p>
            </div>
            <div onClick={() => ketqua()}>
              {" "}
              <img src={hopqua} alt="Kết quả" className="image-footer" />
              <p className="p-footer"> Kết quả</p>
            </div>
          </div>
        </div>
      </div>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          image={notification.image}
          onClose={closeNotification}
        />
      )}
      <PopupModal
        isOpen={isPopupCheck} // Modal visibility controlled by popupVisible state
        onClose={() => setIsPopupCheck(false)} // Close modal on action
      >
        <p>
          Bạn đã tham gia vào trước đó rồi, cảm ơn bạn đã quan tâm chúng tôi
        </p>
        <div className="mt-8">
          <button
            onClick={() => togglePopupCheck()}
            style={{
              color: "#000",
              width: "50%",
              background: "#00ff71",
              height: "40px",
            }}
          >
            Đóng
          </button>
        </div>
      </PopupModal>
      <PopupModal
        isOpen={theledialog} // Modal visibility controlled by popupVisible state
        onClose={() => settheledialog(false)} // Close modal on action
      >
        <div className="thele-container">
          <div></div> {/* Đây là một div trống */}
          <div className="text-center">Thể lệ</div>
          <div
            className="close-ketqua"
            onClick={() => settheledialog(false)}
            style={{
              cursor: "pointer",
              fontSize: "20px",
              fontWeight: "bold",
              color: "red",
            }}
          >
            X
          </div>
        </div>
        <p>Thể lệ vòng quay may mắn</p>
        <p>
          Mỗi zalo chỉ được quay 1 lần và chỉ nhận 1 giải Áp dụng độ tuổi từ 20
          - 55 tuổi.
          <br /> Hết quả tặng có thể quy đổi quà tặng tương đương hoặc đợi vài
          ngày để quà tặng về.
          <br />
          Quà tặng và quay số này đều miễn phí 100%
        </p>
        <p>Lưu ý: Quyết định của ban tổ chức là quyết định cuối cùng.</p>
      </PopupModal>
      <PopupModal
        isOpen={ketquadialog}
        onClose={() => setketquadialog(false)}
        c
      >
        <div className="thele-container">
          <div></div> {/* Đây là một div trống */}
          <div className="text-center">Kết quả</div>
          <div
            className="close-ketqua"
            onClick={() => setketquadialog(false)}
            style={{
              cursor: "pointer",
              fontSize: "20px",
              fontWeight: "bold",
              color: "red",
            }}
          >
            X
          </div>
        </div>
        <table
          border="1"
          cellPadding="10"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              <th>Tên sản phẩm</th>
              <th>Thời gian</th>
              <th>Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {ketquavalue.map((product) => (
              <tr key={product.id}>
                <td>{ketquavalue.description}</td>
                <td>{formatDate(ketquavalue.created_at)}</td>
                <td>
                  {product.status === "0" ? (
                    <p>Đang chờ</p>
                  ) : (
                    <p> Đã xác nhận</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PopupModal>

      <PopupModal isOpen={showGender}>
        <div>
          <div className="title-thongbao">Xin chào</div>
          <div className="title-description">Vui lòng chọn giới tính</div>
          <div
            style={{
              display: "flex",
              marginTop: "16px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: "16px",
              }}
            >
              <img src={NamImage} alt="Nam" height="50px" width="50px" />
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  onChange={handleGenderChange}
                />
                Nam
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={NuImage} alt="Nữ" height="50px" width="50px" />
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="0"
                  onChange={handleGenderChange}
                />
                Nữ
              </label>
            </div>
          </div>{" "}
          <div className="title-description " style={{ padding: "5px 0 " }}>
            Vui lòng nhập ngày tháng năm sinh
          </div>
          <div>
            <input
              type="date"
              id="dob-input"
              value={dateOfBirth}
              onChange={handleInputChange}
              style={{ padding: "5px", fontSize: "16px" }}
            />
          </div>
          <button
            style={{ marginTop: "20px" }}
            onClick={handleSubmit}
            className="submit-button"
          >
            Xác nhận
          </button>
        </div>
      </PopupModal>
      <PopupModal isOpen={showAge}>
        <div className="title-thongbao">Xin chào </div>
        <p>Chương trình chỉ áp dụng cho khách hàng từ 20 đến 60 tuổi.</p>
        <p>Cảm ơn bạn đã qua tâm</p>
      </PopupModal>
    </div>
  );
};

export default SpinWheelComponent;
